<template>
  <div>
    <molecules-loader
      v-if="isLoadingNextStep"
      :is-loading="isLoadingNextStep"
    />

    <div v-else>
      <Step step-name="famille" :v$="v$" :cta-next="ctaLink" @submit="onSubmit">
        <template #start>
          <prismic-rich-text :field="formattedData.title || []" />

          <prismic-rich-text
            class="family-subtitle"
            :field="formattedData.subtitle || []"
          />
        </template>

        <div class="family-form">
          <atoms-input-radio-card
            variant="withPicture"
            name="family"
            :value="peopleComposition"
            :inputs="formattedData.inputs"
            :error="v$.peopleComposition?.$error"
            :error-message="
              v$.peopleComposition && errorMessage(v$.peopleComposition.$errors)
            "
            @change="onChange"
          />

          <div class="hint">
            <foundations-ui-icon name="control-info" />
            <prismic-rich-text class="text" :field="stepsData.family?.hint" />
          </div>
        </div>
      </Step>

      <organisms-highlight-v2
        v-for="(highlight, index) in highlights"
        :key="`highlight-${index}`"
        v-bind="highlight"
      />

      <div id="container-informations">
        <AdvantagesPush />

        <organisms-ratings-push
          :title="formattedData.ratingPushTitle"
          :ratings="ekomi.ratings"
          background="theme"
          :cards="ekomi.ratingCards"
        />

        <foundations-ui-wrapper>
          <prismic-rich-text :field="formattedData.seoText || []" />
        </foundations-ui-wrapper>

        <organisms-contact-push
          :title="formattedData.contactPushV2Title"
          :contacts-item="formattedData.contactsItem"
          :has-version2="true"
          :with-quote="formattedData.withQuote"
          background="theme"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useVuelidate } from '@vuelidate/core'
/* eslint-disable-next-line import/named */
import { isEmpty, isNil, toString } from 'lodash'
import { mapGetters } from 'vuex'

import { PrismicState } from '~/store/prismic/state'
import { FormState } from '~/store/form/state'
import { TgpState } from '~/store/tgp/state'
import { extractHighlightPayloadFromBody } from '~/utils/prismicSlices/highlight'
import { errorMessage, required } from '~/utils/vuelidate'
import { deleteContributor } from '~/utils/api-calls'

import { EkomiPayload } from '~/api-clients/ekomi'
import { PrismicRichTextProps } from '@prismicio/vue'
import { navigateTo } from '#imports'
import * as Sentry from '@sentry/vue'

const PEOPLE_COMPOSITION_VALUES = [
  'alone',
  'couple',
  'aloneChild',
  'coupleChild',
] as const

type PeopleCompositionValue = (typeof PEOPLE_COMPOSITION_VALUES)[number]

export default defineNuxtComponent({
  name: 'FamillePage',
  setup: (props) => {
    const i18n = useI18n()
    const v$ = useVuelidate()
    const router = useRouter()
    const route = router.currentRoute

    return {
      i18n,
      v$,
      router,
      route,
    }
  },
  data() {
    return {
      isLoadingNextStep: false,
      canChangePage: true,
      ctaLink: this.i18n.t('steps.home'),
    } as {
      isLoadingNextStep: boolean
      canChangePage: boolean
      ctaLink: string
    }
  },
  validations: {
    peopleComposition: {
      required: required('Pour continuer, choisissez qui vous voulez assurer.'),
    },
  },
  computed: {
    ...(mapGetters({
      peopleComposition: 'form/peopleComposition',
      stepsData: 'prismic/stepsData',
      createProjectDate: 'tgp/createProjectDate',
    }) as {
      peopleComposition: () => FormState['peopleComposition']
      stepsData: () => PrismicState['stepsData']
      createProjectDate: () => TgpState['createProjectDate']
    }),
    highlights() {
      return extractHighlightPayloadFromBody(
        this.$store.state.prismic.stepsData.family
      )
    },
    ekomi() {
      const i18n = useI18n()

      const ekomiInfo: EkomiPayload['info'] = this.$store.state.index.ekomi.info
      const ekomiFeedback: EkomiPayload['feedbacks'] =
        this.$store.state.index.ekomi.feedbacks || []
      const filteredReviews = ekomiFeedback!.filter(
        (review) => parseInt(review.rating) >= 4 && review.message.length > 30
      )
      const sortedReviews = filteredReviews.sort(
        (a, b) =>
          new Date(b.delivered).getTime() - new Date(a.delivered).getTime()
      )
      const threeMostRecent = sortedReviews.slice(0, 3)

      return {
        ratings: ekomiInfo && {
          rating: parseFloat(ekomiInfo!.fb_avg),
          showText: true,
          showLogo: true,
          variant: 'large',
          logo: 'ekomi',
          ratingLabel: i18n.t('header.ratingLabel', {
            count: ekomiInfo!.fb_count,
          }),
        },
        ratingCards: threeMostRecent?.map((ratingCard) => ({
          rating: parseFloat(ratingCard.rating),
          text: ratingCard.message,
          date: new Date(ratingCard.delivered).toLocaleDateString(),
        })),
      }
    },
    
    formattedData() {
      const familyPrismic = this.$store.state.prismic.stepsData
        .family as PrismicState['stepsData']['family']

      const findContactPushSlice = familyPrismic.body.find(
        (slice: any) => slice.slice_type === 'contact_push'
      ) as PrismicContactPushSlice

      const findSeoSlice = familyPrismic.body.find(
        (slice: any) => slice.slice_type === 'edito_rich_text'
      ) as PrismicEditoRichTextSlice

      const ratingsPush = familyPrismic.ratingsPush

      return {
        title: familyPrismic?.title,
        subtitle: familyPrismic?.subtitle,
        hint: familyPrismic?.hint,
        ratingPushTitle: this.$prismic.asHTML(
          ratingsPush?.title
        ) as PrismicRichTextProps['field'],
        seoText: findSeoSlice?.primary
          ?.content as PrismicRichTextProps['field'],
        contactPushV2Title: this.$prismic.asHTML(
          findContactPushSlice?.primary?.contact_push_title as object[]
        ) as any,
        contactsItem: familyPrismic?.contactPush,
        withQuote: findContactPushSlice?.primary?.with_quote,
        inputs: [
          {
            label: 'Vous',
            value: 'alone' as PeopleCompositionValue,
            picture: {
              alt: '',
              filename: '/images/famille/vous.svg',
            },
          },
          {
            label: 'Votre couple',
            value: 'couple' as PeopleCompositionValue,
            picture: {
              alt: '',
              filename: '/images/famille/votre-couple.svg',
            },
          },
          {
            label: 'Vous et vos enfants',
            value: 'aloneChild' as PeopleCompositionValue,
            picture: {
              alt: '',
              filename: '/images/famille/vous-enfants.svg',
            },
          },
          {
            label: 'Votre couple et vos enfants',
            value: 'coupleChild' as PeopleCompositionValue,
            picture: {
              alt: '',
              filename: '/images/famille/famille.svg',
            },
          },
        ] as never[],
      }
    },
  },
  created() {
    const peopleComposition = this.$route.query.peopleComposition as string

    if (
      !isEmpty(peopleComposition) &&
      PEOPLE_COMPOSITION_VALUES.includes(
        peopleComposition as PeopleCompositionValue
      )
    ) {
      this.isLoadingNextStep = true
    }
  },
  mounted() {
    const peopleComposition = this.route.query.peopleComposition as string

    if (
      this.stepsData.family &&
      !this.stepsData.family.maintenance_mode &&
      !isEmpty(peopleComposition) &&
      PEOPLE_COMPOSITION_VALUES.includes(
        peopleComposition as PeopleCompositionValue
      )
    ) {
      this.setCreateProjectDate()
      this.onChange({ input: { value: peopleComposition } }, 0)
    } else {
      const peopleComposition = this.$store.state.tgp.peopleComposition

      if (!isEmpty(peopleComposition)) {
        this.setPeopleComposition(peopleComposition)
      }

      this.setCreateProjectDate()
    }
  },
  methods: {
    ...(mapGetters({
      nextStep: 'steps/nextStep',
    }) as {
      nextStep: () => string
    }),
    onChange(event: { input: { value: string } }, timeout = 500) {
      if (!this.canChangePage) return

      this.setPeopleComposition(event.input.value)
      this.canChangePage = false

      // go to the next step when the user clicks on a card
      setTimeout(() => {
        this.canChangePage = true
        this.onSubmit()
        const next = `/${toString(this.nextStep())}`
        goTo(this.router as unknown as Router, next)
      }, timeout)
    },
    onSubmit() {
      this.$store.dispatch('tgp/setPeopleComposition', this.peopleComposition)
      const payload: ProjectSituationPayload = {
        peopleComposition: this.peopleComposition,
      }

      this.$bus.$emit('commit-project-situation', payload)
      this.removeOutdatedContributors()
    },
    removeChildContributor(index: number) {
      const { projectId } = this.$store.state.tgp
      const contributorId = this.$store.state.tgp[`child${index}ContributorId`]

      if (!contributorId) {
        return
      }

      // remove childNContributor on API
      deleteContributor(this.$config.public.baseURL, projectId, contributorId)

      // remove childNContributor from tgp state
      this.$store.commit(`tgp/setChild${index}Birthdate`, undefined)
      this.$store.commit(`tgp/setChild${index}ContributorId`, undefined)
      this.$store.commit(`tgp/setChild${index}MandatoryScheme`, undefined)

      // remove childNContributor from cookie __april_project_situation
      const payload: ProjectSituationPayload = {
        [`birthDateChild${index}`]: undefined,
        [`mandatorySchemeRefIdChild${index}`]: undefined,
      }

      this.$bus.$emit('commit-project-situation', payload)
    },
    removeChildrenContributor() {
      for (let index = 0; index < 6; index++) {
        this.removeChildContributor(index)
      }

      // reset child counter to 0
      this.$store.dispatch('tgp/setChildCounter', 0)
    },
    // when user comes back on this page, if some contributors (spouse, childN)
    // have been created previously but are not legit anymore, because peopleComposition
    // changed, they have to be removed from the cookie and from the API
    removeOutdatedContributors() {
      switch (this.peopleComposition) {
        case 'alone':
          this.removeSpouseContributor()
          this.removeChildrenContributor()
          break

        case 'couple':
          this.removeChildrenContributor()
          break

        case 'aloneChild':
          this.removeSpouseContributor()
          break
      }
    },
    removeSpouseContributor() {
      const { projectId, spouseContributorId: contributorId } =
        this.$store.state.tgp

      if (!contributorId) {
        return
      }

      // remove spouseContributor on API
      deleteContributor(this.$config.public.baseURL, projectId, contributorId)

      // remove spouseContributor from tgp state
      this.$store.commit(`tgp/setSpouseBirthdate`, undefined)
      this.$store.commit(`tgp/setSpouseContributorId`, undefined)
      this.$store.commit(`tgp/setSpouseMandatoryScheme`, undefined)

      // remove spouseContributor from cookie __april_project_situation
      const payload: ProjectSituationPayload = {
        birthDateSpouse: undefined,
        mandatorySchemeRefIdSpouse: undefined,
      }

      this.$bus.$emit('commit-project-situation', payload)
    },
    setPeopleComposition(value: string) {
      this.$store.dispatch('form/setPeopleComposition', value)
    },
    setCreateProjectDate() {
      // return if exists
      if (!isNil(this.createProjectDate)) return

      const date = new Date().toISOString().slice(0, 10)

      // otherwise set it to today in format YYYY-MM-DD
      this.$store.dispatch('tgp/setCreateProjectDate', date)

      const payload: ProjectSituationPayload = {
        createProjectDate: date,
      }

      this.$bus.$emit('commit-project-situation', payload)
    },
  },
})
</script>

<style lang="scss">
#famille {
  #button-next {
    width: april-rem(300);
  }

  .step-content {
    margin-bottom: april-rem($spacing-m);
  }

  h1 {
    @include heading-2;
  }
}

.family-form {
  @media #{$mq-medium} {
    margin: 0 -10rem;
  }

  .inputs {
    margin: auto;
    max-width: april-rem(440);

    @media #{$mq-medium} {
      max-width: none;
    }

    .input .content > div {
      display: flex;
      justify-content: center;
    }
  }
}

h1 {
  margin-bottom: 0;
}

.family-subtitle {
  text-align: left;

  @media #{$mq-medium} {
    text-align: center;
  }

  p {
    color: $color-extrabold-gray;
  }
}

#container-informations {
  gap: april-rem($spacing-2xl);
  display: flex;
  flex-direction: column;
  margin-top: april-rem($spacing-2xl);

  @media #{$mq-medium} {
    gap: april-rem(2 * $spacing-2xl);
    border-top: 1px solid $color-medium-gray;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    strong {
      color: $color-medium;
    }
  }

  h2 {
    @include heading-2;
    margin-bottom: april-rem($spacing-l);
    text-align: center;

    @media #{$mq-medium} {
      margin-bottom: april-rem($spacing-xl);
    }
  }

  .ratings-push,
  .contact-push {
    margin-top: 0;
  }

  .ratings-push {
    padding-top: 0;

    @media #{$mq-small} {
      padding: april-rem($spacing-l);
    }

    @media #{$mq-medium} {
      padding: april-rem($spacing-xl);
    }

    .ratings-title-container {
      @media #{$mq-small} {
        margin-bottom: april-rem($spacing-l);
      }

      @media #{$mq-medium} {
        margin-bottom: april-rem($spacing-2xl);
      }

      .ratings-title {
        p {
          strong {
            color: $color-medium;
          }
        }
      }
    }

    .ratings-push-cards {
      @media #{$mq-small} {
        align-items: center;
        flex-direction: column;
        gap: april-rem($spacing-s);
      }

      @media #{$mq-medium} {
        flex-direction: row;
        gap: 0;
      }

      .swiper-wrapper {
        @media #{$mq-small} {
          gap: april-rem($spacing-s);
        }

        @media #{$mq-medium} {
          gap: april-rem($spacing-l);
        }
      }
    }
  }

  .container-informations {
    border-top: 1px solid $color-medium-gray;
  }
}
</style>
